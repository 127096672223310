export interface LinkProperties {
  href: string;
  label: React.ReactNode;
  target?: string;
}

export const aboutUsLinks: LinkProperties[] = [
  //{ href: "/histoire-de-leazly", label: "Notre histoire" },
  { href: "/conciergerie", label: "Conciergerie" },
  {
    href: "/contact",
    label: "Carrières",
  },
  { href: "/blog", label: "Blog" },
  { href: "/faq", label: "Centre d'aide" },
  { href: "/contact", label: "Contact" },
];
export const serviceLinks: LinkProperties[] = [
  { href: "/sous-louer-son-appartement", label: "Locataire" },
  { href: "/location-courte-duree", label: "Propriétaire" },
  { href: "/local-commercial", label: "Local commercial" },
  { href: "/bailleur", label: "Bailleur" },
];
export const partnerLinksHeader: LinkProperties[] = [
  { href: "/partenaire-ecoles", label: "Écoles" },
  { href: "/partenaire-immo", label: "Immobilier" },
  { href: "/partenaire-corporate", label: "Corporate" },
];
export const partnerLinksFooter: LinkProperties[] = [
  { href: "/partenaire-ecoles", label: "Écoles" },
  { href: "/partenaire-immo", label: "Agences immobilières" },
  { href: "/partenaire-corporate", label: "Entreprises" },
];
