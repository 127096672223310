import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { TreeItem, TreeView } from "@mui/lab";
import {
  Container,
  Grid,
  Link as MuiLink,
  Typography,
  Stack,
  Link,
  styled,
  Box,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/sharp-solid-svg-icons";

import {
  type LinkProperties,
  aboutUsLinks,
  partnerLinksFooter,
  serviceLinks,
} from "./links";
import safeLink from "./safeLink";

const StyledTreeView = styled(TreeView)`
  .MuiTreeItem-content {
    flex-direction: row-reverse;
    padding-bottom: 0.5rem;
    color: ${({ theme }) => theme.palette.navy.main};
    padding-left: 0;
  }
  .MuiTreeItem-label {
    padding-left: 0 !important;
    white-space: nowrap;
  }
  width: 170px;
  .Mui-selected {
    background-color: white !important;
  }
  .Mui-selected:focus {
    background-color: white !important;
  }
`;

const logo = (
  <svg
    height="35px"
    width="37px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="50 310 600 10"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0466C8"
      stroke="none"
    >
      <path d="M2080 4810 c-136 -13 -275 -39 -399 -77 l-116 -36 -298 -3 c-272 -4 -301 -6 -336 -24 -72 -36 -146 -154 -170 -268 -17 -80 -14 -613 3 -679 22 -82 61 -158 106 -207 66 -72 91 -76 436 -76 264 0 294 -2 312 -17 23 -21 56 -60 331 -397 412 -503 410 -501 551 -501 66 0 96 5 133 22 l48 22 -3 -111 -3 -112 -84 -29 c-46 -15 -102 -41 -125 -56 -146 -101 -226 -286 -197 -451 22 -124 97 -243 190 -302 25 -15 50 -28 55 -28 6 0 24 25 40 56 50 95 134 133 221 100 53 -21 90 -62 105 -116 6 -22 15 -42 21 -45 5 -4 32 8 60 25 190 122 259 380 158 585 -54 111 -179 213 -287 236 -88 19 -92 26 -92 139 0 53 2 99 5 102 3 3 31 -5 63 -18 75 -31 135 -31 199 1 58 29 96 74 127 149 20 49 21 69 24 544 2 270 6 492 10 492 4 0 86 -62 182 -137 207 -161 267 -193 360 -193 184 0 299 156 246 334 -22 72 -53 105 -248 258 -99 79 -186 148 -194 154 -8 6 -14 14 -14 18 0 3 137 6 305 6 277 0 309 2 355 19 117 44 187 138 177 236 -10 102 -69 175 -170 211 -48 17 -100 19 -662 24 l-610 5 -44 24 c-119 63 -278 104 -472 121 -126 11 -173 11 -299 0z m416 -909 c55 -25 111 -76 135 -123 17 -34 21 -67 26 -231 3 -106 3 -249 -2 -319 l-7 -127 -49 56 c-126 144 -381 466 -394 497 -21 50 -19 85 10 143 52 103 179 150 281 104z m302 -1766 c44 -36 58 -77 43 -125 -41 -137 -231 -104 -231 40 0 34 6 48 34 76 29 29 41 34 79 34 34 0 53 -6 75 -25z" />
      <path d="M2649 1585 c-59 -32 -59 -35 -59 -592 0 -572 -1 -560 73 -597 48 -23 96 -15 140 24 l32 28 3 529 c3 581 5 569 -58 606 -34 21 -96 21 -131 2z" />
    </g>
  </svg>
);
export const Footer: React.FC = () => (
  <Container component="footer" sx={{ mt: "auto", pb: 3 }}>
    <Grid container component="section" spacing={4} py={5}>
      <Grid item xs={12} md={6} display="flex" flexDirection="column">
        <SvgIcon fontSize="large">{logo}</SvgIcon>
        <Typography display={{ xs: "none", md: "initial" }}>
          L’agence immo conçue pour voyager sans payer son loyer
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledTreeView
          defaultCollapseIcon={
            <Box
              component={FontAwesomeIcon}
              fontSize="17px !important"
              pl={2}
              icon={faMinus}
            />
          }
          defaultExpandIcon={
            <Box
              component={FontAwesomeIcon}
              pl={2}
              fontSize="17px !important"
              icon={faPlus}
            />
          }
          defaultEndIcon={true}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <TreeItem
            nodeId="1"
            label={<Typography fontWeight={600}>À propos de nous</Typography>}
          >
            {aboutUsLinks.map(({ href, label }, i) => (
              <Link
                key={"about-us-" + i}
                {...safeLink(href)}
                underline="none"
                variant="subtitle2"
              >
                <TreeItem nodeId={"1" + i + 1} label={label} />
              </Link>
            ))}
          </TreeItem>
          <TreeItem
            nodeId="50"
            label={<Typography fontWeight={600}>Services</Typography>}
          >
            {serviceLinks.map(({ href, label }, i) => (
              <Link
                key={"service-" + i}
                {...safeLink(href)}
                underline="none"
                color="grey.700"
                variant="subtitle2"
              >
                <TreeItem nodeId={"50" + i + 1} label={label} />
              </Link>
            ))}
          </TreeItem>
          <TreeItem
            nodeId="100"
            label={<Typography fontWeight={600}>Partenariats</Typography>}
          >
            {partnerLinksFooter.map(({ href, label }, i) => (
              <Link
                key={"partner-" + i}
                {...safeLink(href)}
                underline="none"
                color="grey.700"
                variant="subtitle2"
              >
                <TreeItem nodeId={"100" + i + 1} label={label} />
              </Link>
            ))}
          </TreeItem>
        </StyledTreeView>
        <Stack
          display={{ xs: "none", md: "flex" }}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          rowGap={2}
        >
          <FooterColumn title="À propos de nous" links={aboutUsLinks} />
          <FooterColumn title="Services" links={serviceLinks} />
          <FooterColumn title="Partenariats" links={partnerLinksFooter} />
        </Stack>
      </Grid>
    </Grid>
    <Stack
      borderTop={1}
      borderColor={{ xs: "navy.main", md: "grey.700" }}
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent={{ md: "space-between" }}
      alignItems={{ md: "center" }}
    >
      <Typography fontSize={{ xs: 14, md: 16 }}>
        © {new Date().getFullYear()}, Leazly. Tous droits réservés. &nbsp;
        <Link
          {...safeLink("/conditions-generales-dutilisation")}
          color="#0466C9"
        >
          Conditions
        </Link>
      </Typography>
      <Stack direction="row" justifyContent={{ xs: "center", md: "normal" }}>
        {social.map((props, index) => (
          <SocialIcon key={index} {...props} />
        ))}
      </Stack>
    </Stack>
  </Container>
);

const SocialIcon: React.FC<LinkProperties> = ({ href, label, target }) => (
  <MuiLink href={href} target={target}>
    <IconButton>{label}</IconButton>
  </MuiLink>
);
const social: LinkProperties[] = [
  {
    href: "https://www.youtube.com/",
    label: <YouTubeIcon />,
    target: "_blank",
  },
  {
    href: "https://www.facebook.com/Leazly",
    label: <FacebookIcon />,
    target: "_blank",
  },
  {
    href: "https://www.linkedin.com/company/100429107",
    label: <LinkedInIcon />,
    target: "_blank",
  },
  {
    href: "https://twitter.com/",
    label: <TwitterIcon />,
    target: "_blank",
  },
  {
    href: "https://www.instagram.com/leazly__",
    label: <InstagramIcon />,
    target: "_blank",
  },
];

const FooterColumnLink: React.FC<LinkProperties> = ({ href, label }) => (
  <Link
    {...safeLink(href)}
    underline="none"
    color="grey.700"
    variant="subtitle2"
  >
    {label}
  </Link>
);
const FooterColumn: React.FC<{
  title: string;
  links: LinkProperties[];
}> = ({ title, links }) => (
  <Stack direction="column" gap={2}>
    <Typography fontWeight={600}>{title}</Typography>
    {links.map((link, key) => (
      <FooterColumnLink key={key} {...link} />
    ))}
  </Stack>
);
